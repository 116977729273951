.anchor {
    --text-color: var(--anchor_text-color, currentcolor);
    --text-hover-color: var(--anchor_text-hover-color, var(--color-blue));
    --border-color: var(--anchor-border-color, var(--color-white));
    padding-bottom: 1px;
    position: relative;
    color: var(--text-color);
    text-decoration: none;
    border-bottom: 1px solid hsla(var(--border-color), 0.33);

    &:focus,
    &:hover {
        color: hsl(var(--text-hover-color));
        transition: color var(--transition-in) var(--transition-timing);

        &::before {
            transform: scale(1);
            transform-origin: left center;
        }
    }

    &::before {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 0;
        content: '';
        background: currentcolor;
        transform: scale(0);
        transform-origin: right center;
        transition-property: transform, opacity;
        transition-duration: var(--transition-in);
        transition-timing-function: var(--ease-in-out);
    }
}
