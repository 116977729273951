.noscript {
    width: 100vw;
    min-height: 100vh;
    padding: var(--global-header-height) 0 calc(6 * var(--gap));
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    a {
        color: hsl(var(--color-blue));

        &:focus,
        &:hover {
            color: hsl(var(--color-blue-lighten-3));
            text-decoration: none;
        }
    }
}
