.footer {
    padding: calc(6 * var(--gap)) 0;
    justify-content: center;
    background: hsla(var(--color-black-lighten-5), 0.5);
}

.heading {
    width: 100%;
    margin-bottom: calc(4 * var(--gap));
    display: block;
    font-family: 'Avenir UltraLight', Ubuntu, Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    font-size: 4rem;
    line-height: 1.2;
    text-align: center;
}

.section {
    margin-top: calc(6 * var(--gap));
    display: flex;
    flex-direction: column;

    @media (--min-md) {
        margin-top: 0;
        flex-direction: row;
    }
}

.subsection {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: calc(3 * var(--gap));
    padding: 0 var(--gap);
    display: flex;
    flex-direction: column;

    @media (--min-md) {
        max-width: 50%;
        flex-basis: 50%;
    }
}

.terms {
    width: 100%;
    padding: 0 var(--gap);
}

.list {
    font-size: 0.95rem;

    + .list {
        margin-top: var(--gap);
    }
}

.item {

    + .item {
        margin-top: calc(0.5 * var(--gap));
    }
}

.link {
    --anchor-border-color: var(--color-blue-lighten-3);
    color: hsl(var(--color-blue-lighten-3));

    &:focus,
    &:hover {
        text-decoration: none;
    }
}

.grant {
    color: hsl(var(--color-gray-lighten-5));

    &:focus,
    &:hover {
        text-decoration: none;
    }
}
