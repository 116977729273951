.control {
    --background-color: var(--form_background-color, var(--color-white));
    --text-color: var(--form_text-color, var(--color-black));
    --focus-color: var(--form_focus-color, var(--color-black));
    --border-color: var(--form_border-color, var(--color-black));
    --label-color: var(--form_label-color, var(--color-black));
    --border-radius: var(--global-border-radius, 4px);
    width: 100%;
    margin: var(--gap) auto calc(3 * var(--gap));
    position: relative;
}

.textarea,
.label {
    width: 100%;
    padding: calc(0.75 * var(--gap)) var(--gap);
}

.textarea {
    height: 50px;
    overflow: hidden;
    background: hsl(var(--background-color));
    color: hsl(var(--text-color));
    resize: none;
    caret-color: hsl(var(--border-color));
    border-width: 0 0 2px;
    border-style: solid;
    border-color: transparent transparent hsla(var(--border-color), 0.5) transparent;

    &:not(.disabled):focus {
        box-shadow: 0 0 0 3px hsla(var(--color-white), 0.25);
    }

    &:focus,
    &[placeholder] {

        + .label {

            .labelContent {
                transform: translate3d(0, -2.5em, 0) scale3d(0.9, 0.9, 1);
            }
        }
    }

    &:focus {
        border-radius: var(--border-radius);
    }

    &::placeholder {
        color: hsla(var(--form_placeholder-color, var(--color-black)), 0.4);
        font-size: 0.9rem;
    }

    &::selection {
        background: hsla(var(--border-color), 0.75);
        transition: background-color var(--transition-out) var(--transition-timing);
    }

    &[readonly] {
        color: hsla(var(--form_readonly-color, var(--color-black)), 0.75);
        border-radius: var(--border-radius);
        border: 1px dotted;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.hasMaxlength {

    .textarea {
        padding-right: 90px;
    }
}

.nonempty {

    + .label {

        .labelContent {
            color: hsl(var(--label-color));
            transform: translate3d(0, -2.5em, 0) scale3d(0.9, 0.9, 1);
        }
    }
}

.label {
    width: auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border: 1px solid transparent;
}

.labelContent {
    display: inline-flex;
    transform-origin: left center;
    transition-property: transform;
    transition-duration: var(--transition-in);
    transition-timing-function: var(--ease-out-back);
}

.required {
    position: absolute;
    top: calc(0.6 * var(--gap));
    left: calc(0.4 * var(--gap));
    color: hsl(var(--global_danger-color, var(--color-red)));
    font-size: 0.9rem;
}

.error {
    margin-left: var(--gap);
    display: inline-block;
    color: hsl(var(--global_danger-color, var(--color-red)));
    font-size: 0.9em;
}

.maxlength {
    padding: 0 var(--gap);
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 1px;
    background: hsl(var(--form_maxlength-background, var(--background-color)));
    color: hsl(var(--form_maxlength-color, var(--color-black)));
    font-size: 0.75em;
    transform: translateY(-50%);
}

.success {
    --variant: var(--global_success-color, var(--color-green));
}

.info {
    --variant: var(--global_info-color, var(--color-blue));
}

.warning {
    --variant: var(--global_warning-color, var(--color-yellow));
}

.danger {
    --variant: var(--global_danger-color, var(--color-red));
}

.success,
.info,
.warning,
.danger {

    &,
    .textarea:focus,
    .textarea:hover {
        --focus-color: var(--variant);
        --border-color: var(--variant);
        --label-color: var(--variant);
    }
}
