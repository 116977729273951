.section {
    padding: var(--global-header-height) 0 calc(6 * var(--gap));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    @media (--max-sm) {
        width: 100%;
        padding-right: var(--gap);
        padding-left: var(--gap);
    }
}

.homepage {
    padding-right: 0;
    padding-left: 0;
}
