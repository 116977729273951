@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: var(--opacity);
    }
}

@keyframes fade-out {
    0% {
        opacity: var(--opacity);
    }
    100% {
        opacity: 0;
    }
}

.button {
    position: relative;
    z-index: var(--zindex-menu);
    line-height: 0;

    &[disabled] {
        pointer-events: none;
    }
}

.icon {
    width: 80px;
    height: 80px;
    fill: none;
    stroke: hsl(var(--color-white));
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: round;

    path {
        transform-origin: center;
    }
}

.disabled {
    pointer-events: none;
}
