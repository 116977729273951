.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 50%;
    z-index: var(--zindex-header);
    background: linear-gradient(hsla(var(--color-black), 0.75) 60%, hsla(var(--color-black), 0));
    transform: translateX(-50%);
}

.content {
    height: 10vh;
    height: var(--global-header-height);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @media (--max-md) {
        width: 100%;
        padding: 0 calc(2 * var(--gap));
    }
}

.link {
    max-width: 160px;
    color: hsl(var(--color-gray-lighten-5));
    text-decoration: none;

    @media (--min-md) {
        max-width: 210px;
    }
}

.logo {
    width: 100%;
    height: 100%;
}

.title {
    display: flex;
    justify-content: center;
}
