.main {
    width: 100vw;
    min-height: 100vh;
    background-color: hsl(var(--global-background));
    color: hsl(var(--global-text));
    font-family: 'Helvetica', Ubuntu, Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    font-weight: 300;
    transition: filter var(--transition-out) var(--transition-timing);

    noscript {
        margin: 0 auto;
        text-align: center;
    }
}

.section {
    padding-bottom: 0;
}

.image {
    text-align: center;

    img {
        width: 100%;
    }
}

.footer {
    padding: calc(6 * var(--gap)) 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--min-md) {
        flex-direction: row;
        align-items: center;
    }
}

.logo {
    max-width: 4rem;
    max-height: 4rem;

    @media (--min-md) {
        margin-right: var(--gap);
    }
}

.paragraph {
    width: 100%;
    max-width: 80ch;
    margin: calc(2 * var(--gap)) auto;
    color: hsl(var(--color-gray-lighten-5));

    @media (--min-md) {
        width: 40%;
        margin-right: auto;
    }

    @media (--min-lg) {
        width: 50%;
    }
}

.nav {
    margin: 0 auto;

    @media (--min-md) {
        margin-right: 0;
        padding-left: var(--gap);
    }
}
