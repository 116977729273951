.list {
    display: flex;
    flex-direction: column;

    @media (--min-sm) {
        margin-left: var(--gap);
        flex-direction: row;
    }

    @media (--min-lg) {
        margin-left: calc(2 * var(--gap));
    }
}

.item {
    text-align: center;
    transition: background-color var(--transition-in) var(--transition-duration);

    &:first-child {
        background-color: hsla(var(--color-red), 0.25);
        border-radius: var(--global-border-radius) var(--global-border-radius) 0 0;

        &:not(.active):focus,
        &:not(.active):hover {
            background-color: hsla(var(--color-red), 0.33);
        }

        @media (--min-sm) {
            border-radius: var(--global-border-radius) 0 0 var(--global-border-radius);
        }
    }

    &:nth-child(2) {
        background-color: hsla(var(--color-yellow-darken-2), 0.25);

        &:not(.active):focus,
        &:not(.active):hover {
            background-color: hsla(var(--color-yellow-darken-2), 0.33);
        }
    }

    &:nth-child(3) {
        background-color: hsla(var(--color-blue), 0.25);

        &:not(.active):focus,
        &:not(.active):hover {
            background-color: hsla(var(--color-blue), 0.33);
        }
    }

    &:nth-child(4) {
        background-color: hsla(var(--color-red), 0.25);
        border-radius: 0 0 var(--global-border-radius) var(--global-border-radius);

        &:not(.active):focus,
        &:not(.active):hover {
            background-color: hsla(var(--color-red), 0.33);
        }

        @media (--min-sm) {
            border-radius: 0 var(--global-border-radius) var(--global-border-radius) 0;
        }
    }

    + .item {
        margin-top: 1px;

        @media (--min-sm) {
            margin-top: 0;
            margin-left: 1px;
        }
    }
}

.active {

    &:first-child {
        background: hsla(var(--color-red), 0.9);
    }

    &:nth-child(2) {
        background: hsla(var(--color-yellow-darken-2), 0.9);
    }

    &:nth-child(3) {
        background: hsla(var(--color-blue), 0.9);
    }

    &:nth-child(4) {
        background: hsla(var(--color-red), 0.9);
    }
}

.link {
    min-width: 32px;
    padding: calc(var(--gap) / 3) calc(var(--gap) / 2);
    display: block;
    color: hsl(var(--color-white));
    font-size: 0.9rem;
    text-decoration: none;
}
