@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader,
.loader::before,
.loader::after {
    --max-size: 80px;
    position: absolute;
    z-index: var(--zindex-loader);
    animation-name: spin;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    border-radius: 50%;
    border: 2px solid transparent;
}

.loader {
    --color-outer-1: var(--color-green);
    --color-outer-2: var(--color-blue);
    --color-inner-1: var(--color-red);
    --color-inner-2: var(--color-yellow);
    width: 20vw;
    max-width: var(--max-size);
    height: 20vw;
    max-height: var(--max-size);
    margin-left: -20px;
    top: 15px;
    left: 50%;
    animation-duration: 1.2s;
    border-top-color: hsla(var(--color-outer-1), 0.5);
    border-bottom-color: hsla(var(--color-outer-2), 0.5);

    &::before {
        top: 5%;
        right: 5%;
        bottom: 5%;
        left: 5%;
        content: '';
        animation-duration: 1.8s;
        border-top-color: hsla(var(--color-inner-1), 0.75);
    }

    &::after {
        top: 12%;
        right: 12%;
        bottom: 12%;
        left: 12%;
        content: '';
        animation-duration: 1.2s;
        border-top-color: hsla(var(--color-inner-2), 0.9);
    }
}
