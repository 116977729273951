.wrapper {
    position: relative;
}

.form {
    max-width: 60ch;

    @media (--min-md) {
        max-width: 75%;
    }
}

.submit {
    margin-right: auto;
    margin-left: auto;

    @media (--min-md) {
        margin-right: 0;
    }
}

.loader {
    top: 25%;
}

.med {
    display: none;
}
