.nav {
    margin-left: auto;

    @media (--max-md) {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        background: hsla(var(--color-black), 0.8);

        .list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media (--min-md) {
        opacity: 1 !important;
        visibility: visible !important;
    }
}

.open {

    @media (--max-md) {
        z-index: var(--zindex-nav);
    }
}

.list {
    display: none;
    list-style-type: none;

    @media (--min-md) {
        display: block;
    }
}

.item {
    display: inline-block;

    + .item {
        margin-top: calc(2 * var(--gap));
    }

    @media (--min-md) {
        opacity: 1 !important;
        transform: translate(0, 0) !important;

        + .item {
            margin-top: 0;
            margin-left: var(--gap);
        }
    }
}

.link {
    color: hsl(var(--global-text));
    font-size: 1.5rem;

    @media (--min-md) {
        font-size: 0.9rem;
    }
}

.active {
    color: hsl(var(--color-gray-lighten-5));
    pointer-events: none;
    border-bottom: none;
}

.menu {

    @media (--min-md) {
        display: none;
    }
}
