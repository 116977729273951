.address {
    margin-bottom: calc(3 * var(--gap));
    text-align: center;

    &,
    a {
        color: hsl(var(--color-gray-lighten-5));
        font-size: 0.9rem;
        line-height: 1.5;
    }

    strong {
        margin-bottom: var(--gap);
        display: inline-block;
        color: hsl(var(--color-white));
        font-size: 1.25em;
    }

    span {
        margin-bottom: calc(0.5 * var(--gap));
        display: inline-block;
        color: hsl(var(--color-white));
        font-size: 1em;
    }

    @media (--min-md) {
        text-align: left;
    }
}

.socials {
    width: 100%;
    margin: 0 auto calc(3 * var(--gap));
    display: flex;
    justify-content: center;

    @media (--min-md) {
        justify-content: flex-start;
    }
}

.social {
    margin: 0 var(--gap);
    display: inline-flex;

    @media (--min-md) {
        margin-left: 0;
    }
}

.description {
    max-width: 80ch;
    margin: 0 auto;
    color: hsl(var(--color-gray-lighten-5));
    font-size: 0.95rem;
    line-height: 1.5;
    text-align: center;

    @media (--min-md) {
        margin-left: 0;
        text-align: left;
    }
}
